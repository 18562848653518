/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SalesConfigPaymentTerminalResult } from '../models/SalesConfigPaymentTerminalResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SalesConfigPaymentTerminalsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns SalesConfigPaymentTerminalResult
     * @throws ApiError
     */
    public getSalesConfigPaymentTerminalsIndex(): CancelablePromise<Array<SalesConfigPaymentTerminalResult>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sales/config/payments/terminals',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public postSalesConfigPaymentTerminalsCreate({
        requestBody,
    }: {
        requestBody: {
            registrationCode: string;
            label: string;
            locationId: string;
        },
    }): CancelablePromise<{
        id: string;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sales/config/payments/terminals',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public postSalesConfigPaymentTerminalsClear({
        id,
    }: {
        id: string,
    }): CancelablePromise<{
        ok: boolean;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sales/config/payments/terminals/{id}/clear',
            path: {
                'id': id,
            },
        });
    }

}
