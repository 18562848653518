/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { CoreEtlService } from './services/CoreEtlService';
import { CoreInstallsService } from './services/CoreInstallsService';
import { CoreLocationsService } from './services/CoreLocationsService';
import { CoreUploadsService } from './services/CoreUploadsService';
import { CrmConfigFileCategoriesService } from './services/CrmConfigFileCategoriesService';
import { CrmContactFilesService } from './services/CrmContactFilesService';
import { CrmContactNotesService } from './services/CrmContactNotesService';
import { CrmContactPaymentMethodsService } from './services/CrmContactPaymentMethodsService';
import { CrmContactsService } from './services/CrmContactsService';
import { CrmPublicWebhookService } from './services/CrmPublicWebhookService';
import { DisplayDevicesService } from './services/DisplayDevicesService';
import { FormsService } from './services/FormsService';
import { FormsPublicFormsService } from './services/FormsPublicFormsService';
import { FormSubmissionsService } from './services/FormSubmissionsService';
import { HealthcheckService } from './services/HealthcheckService';
import { InventoryCategoriesService } from './services/InventoryCategoriesService';
import { InventoryItemsService } from './services/InventoryItemsService';
import { InventoryItemsImportService } from './services/InventoryItemsImportService';
import { InventoryVendorsService } from './services/InventoryVendorsService';
import { ItPublicFreshdeskBridgeService } from './services/ItPublicFreshdeskBridgeService';
import { SalesConfigPaymentAccountsService } from './services/SalesConfigPaymentAccountsService';
import { SalesConfigPaymentOverviewService } from './services/SalesConfigPaymentOverviewService';
import { SalesConfigPaymentTerminalsService } from './services/SalesConfigPaymentTerminalsService';
import { SalesConfigPaymentTerminalsLocationsService } from './services/SalesConfigPaymentTerminalsLocationsService';
import { SalesConfigProductCategoriesService } from './services/SalesConfigProductCategoriesService';
import { SalesConfigTaxesService } from './services/SalesConfigTaxesService';
import { SalesFinancialAccountsService } from './services/SalesFinancialAccountsService';
import { SalesFinancialTransactionsService } from './services/SalesFinancialTransactionsService';
import { SalesGiftCardsService } from './services/SalesGiftCardsService';
import { SalesGiftCardsPublicRenderService } from './services/SalesGiftCardsPublicRenderService';
import { SalesIntegrationsQboConnectionsService } from './services/SalesIntegrationsQboConnectionsService';
import { SalesLayoutsService } from './services/SalesLayoutsService';
import { SalesMembershipTypesService } from './services/SalesMembershipTypesService';
import { SalesProductsService } from './services/SalesProductsService';
import { SalesPublicStripeWebhookService } from './services/SalesPublicStripeWebhookService';
import { SalesReportsService } from './services/SalesReportsService';
import { SalesSalespointsService } from './services/SalesSalespointsService';
import { SalesTabPaymentsService } from './services/SalesTabPaymentsService';
import { SalesTabReceiptsService } from './services/SalesTabReceiptsService';
import { SalesTabsService } from './services/SalesTabsService';
import { SalesXCrmMembershipsService } from './services/SalesXCrmMembershipsService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class ApiClient {

    public readonly coreEtl: CoreEtlService;
    public readonly coreInstalls: CoreInstallsService;
    public readonly coreLocations: CoreLocationsService;
    public readonly coreUploads: CoreUploadsService;
    public readonly crmConfigFileCategories: CrmConfigFileCategoriesService;
    public readonly crmContactFiles: CrmContactFilesService;
    public readonly crmContactNotes: CrmContactNotesService;
    public readonly crmContactPaymentMethods: CrmContactPaymentMethodsService;
    public readonly crmContacts: CrmContactsService;
    public readonly crmPublicWebhook: CrmPublicWebhookService;
    public readonly displayDevices: DisplayDevicesService;
    public readonly forms: FormsService;
    public readonly formsPublicForms: FormsPublicFormsService;
    public readonly formSubmissions: FormSubmissionsService;
    public readonly healthcheck: HealthcheckService;
    public readonly inventoryCategories: InventoryCategoriesService;
    public readonly inventoryItems: InventoryItemsService;
    public readonly inventoryItemsImport: InventoryItemsImportService;
    public readonly inventoryVendors: InventoryVendorsService;
    public readonly itPublicFreshdeskBridge: ItPublicFreshdeskBridgeService;
    public readonly salesConfigPaymentAccounts: SalesConfigPaymentAccountsService;
    public readonly salesConfigPaymentOverview: SalesConfigPaymentOverviewService;
    public readonly salesConfigPaymentTerminals: SalesConfigPaymentTerminalsService;
    public readonly salesConfigPaymentTerminalsLocations: SalesConfigPaymentTerminalsLocationsService;
    public readonly salesConfigProductCategories: SalesConfigProductCategoriesService;
    public readonly salesConfigTaxes: SalesConfigTaxesService;
    public readonly salesFinancialAccounts: SalesFinancialAccountsService;
    public readonly salesFinancialTransactions: SalesFinancialTransactionsService;
    public readonly salesGiftCards: SalesGiftCardsService;
    public readonly salesGiftCardsPublicRender: SalesGiftCardsPublicRenderService;
    public readonly salesIntegrationsQboConnections: SalesIntegrationsQboConnectionsService;
    public readonly salesLayouts: SalesLayoutsService;
    public readonly salesMembershipTypes: SalesMembershipTypesService;
    public readonly salesProducts: SalesProductsService;
    public readonly salesPublicStripeWebhook: SalesPublicStripeWebhookService;
    public readonly salesReports: SalesReportsService;
    public readonly salesSalespoints: SalesSalespointsService;
    public readonly salesTabPayments: SalesTabPaymentsService;
    public readonly salesTabReceipts: SalesTabReceiptsService;
    public readonly salesTabs: SalesTabsService;
    public readonly salesXCrmMemberships: SalesXCrmMembershipsService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '0.0.1',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.coreEtl = new CoreEtlService(this.request);
        this.coreInstalls = new CoreInstallsService(this.request);
        this.coreLocations = new CoreLocationsService(this.request);
        this.coreUploads = new CoreUploadsService(this.request);
        this.crmConfigFileCategories = new CrmConfigFileCategoriesService(this.request);
        this.crmContactFiles = new CrmContactFilesService(this.request);
        this.crmContactNotes = new CrmContactNotesService(this.request);
        this.crmContactPaymentMethods = new CrmContactPaymentMethodsService(this.request);
        this.crmContacts = new CrmContactsService(this.request);
        this.crmPublicWebhook = new CrmPublicWebhookService(this.request);
        this.displayDevices = new DisplayDevicesService(this.request);
        this.forms = new FormsService(this.request);
        this.formsPublicForms = new FormsPublicFormsService(this.request);
        this.formSubmissions = new FormSubmissionsService(this.request);
        this.healthcheck = new HealthcheckService(this.request);
        this.inventoryCategories = new InventoryCategoriesService(this.request);
        this.inventoryItems = new InventoryItemsService(this.request);
        this.inventoryItemsImport = new InventoryItemsImportService(this.request);
        this.inventoryVendors = new InventoryVendorsService(this.request);
        this.itPublicFreshdeskBridge = new ItPublicFreshdeskBridgeService(this.request);
        this.salesConfigPaymentAccounts = new SalesConfigPaymentAccountsService(this.request);
        this.salesConfigPaymentOverview = new SalesConfigPaymentOverviewService(this.request);
        this.salesConfigPaymentTerminals = new SalesConfigPaymentTerminalsService(this.request);
        this.salesConfigPaymentTerminalsLocations = new SalesConfigPaymentTerminalsLocationsService(this.request);
        this.salesConfigProductCategories = new SalesConfigProductCategoriesService(this.request);
        this.salesConfigTaxes = new SalesConfigTaxesService(this.request);
        this.salesFinancialAccounts = new SalesFinancialAccountsService(this.request);
        this.salesFinancialTransactions = new SalesFinancialTransactionsService(this.request);
        this.salesGiftCards = new SalesGiftCardsService(this.request);
        this.salesGiftCardsPublicRender = new SalesGiftCardsPublicRenderService(this.request);
        this.salesIntegrationsQboConnections = new SalesIntegrationsQboConnectionsService(this.request);
        this.salesLayouts = new SalesLayoutsService(this.request);
        this.salesMembershipTypes = new SalesMembershipTypesService(this.request);
        this.salesProducts = new SalesProductsService(this.request);
        this.salesPublicStripeWebhook = new SalesPublicStripeWebhookService(this.request);
        this.salesReports = new SalesReportsService(this.request);
        this.salesSalespoints = new SalesSalespointsService(this.request);
        this.salesTabPayments = new SalesTabPaymentsService(this.request);
        this.salesTabReceipts = new SalesTabReceiptsService(this.request);
        this.salesTabs = new SalesTabsService(this.request);
        this.salesXCrmMemberships = new SalesXCrmMembershipsService(this.request);
    }
}

