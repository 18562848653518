/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IFaxResponse } from '../models/IFaxResponse';
import type { ISendFaxRequest } from '../models/ISendFaxRequest';
import type { ISingleFaxResponse } from '../models/ISingleFaxResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FaxesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns IFaxResponse
     * @throws ApiError
     */
    public getFaxesListFaxes({
        id,
        q,
        beforeId,
    }: {
        id?: string,
        q?: string,
        beforeId?: string,
    }): CancelablePromise<Array<IFaxResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/faxes',
            query: {
                'id': id,
                'q': q,
                'beforeId': beforeId,
            },
        });
    }

    /**
     * @returns IFaxResponse
     * @throws ApiError
     */
    public postFaxesSendFax({
        requestBody,
    }: {
        requestBody: ISendFaxRequest,
    }): CancelablePromise<IFaxResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/faxes',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ISingleFaxResponse
     * @throws ApiError
     */
    public getFaxesGetFax({
        id,
    }: {
        id: string,
    }): CancelablePromise<ISingleFaxResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/faxes/{id}',
            path: {
                'id': id,
            },
        });
    }

}
