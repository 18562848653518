/* eslint-disable @typescript-eslint/no-explicit-any */
import { trace } from '@opentelemetry/api';
import * as Sentry from '@sentry/vue';
import { type ICancelablePromise, type IOpenApiClient, type IOpenApiError, isOpenApiError } from '@signal24/openapi-client-codegen/browser';
import { installApiClientInterceptors } from '@signal24/vue-foundation';
import type { Class } from 'type-fest';

import { tracer } from '@/otel';

export type IRequestOptions = Parameters<NonNullable<Parameters<typeof installApiClientInterceptors>[0]['onRequest']>>[0];

interface IOptions {
    apiClient: IOpenApiClient;
    CancelablePromise: Class<ICancelablePromise>;
    getHeaders?: () => Record<string, string | undefined>;
    handle401?: (err: IOpenApiError) => void;
    onRequest?: (options: IRequestOptions) => IRequestOptions;
}

export function configureApiClientShared({ apiClient, CancelablePromise, getHeaders, handle401, onRequest }: IOptions) {
    installApiClientInterceptors({
        apiClient,
        CancelablePromise: CancelablePromise,

        wrapper(options, fn) {
            return new CancelablePromise((resolve: (value: any) => void, reject: (err: any) => void, onCancel: (handler: () => void) => void) => {
                return tracer.startActiveSpan('api', span => {
                    const promise = fn(options);
                    onCancel(promise.cancel);
                    promise
                        .then(resolve)
                        .catch(reject)
                        .finally(() => span.end());
                });
            }) as any;
        },

        onRequest(options) {
            options = onRequest ? onRequest(options) : options;

            return {
                ...options,
                headers: {
                    ...options.headers,
                    ...getHeaders?.()
                }
            };
        },

        onError(err) {
            if (isOpenApiError(err)) {
                if (err.status === 401) {
                    handle401?.(err);
                    return err;
                }

                // handle validation errors
                if (
                    err.status === 400 &&
                    typeof err.body === 'object' &&
                    'message' in err.body &&
                    typeof err.body.message === 'string' &&
                    err.body.message.startsWith('Validation error:')
                ) {
                    err.message = err.body.message;
                }
            }

            Sentry.withScope(scope => {
                const spanCtx = trace.getActiveSpan()?.spanContext();
                if (spanCtx) {
                    scope.setContext('trace', {
                        trace_id: spanCtx.traceId,
                        span_id: spanCtx.spanId
                    });
                }
                Sentry.captureException(err);
            });
        }
    });
}
