import type { IIdentityResponse } from '@/openapi-clients-generated/auth';

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        FreshworksWidget?: any;
        fwSettings?: {
            widget_id: number;
            locale: string;
        };
    }
}

export class Freshdesk {
    static isLoaded = false;

    static prepare() {
        // configure freshdesk widget
        window.fwSettings = {
            widget_id: 150000004176,
            locale: 'en'
        };

        // set up widget object
        if (!window.FreshworksWidget) {
            window.FreshworksWidget = function () {
                // eslint-disable-next-line prefer-rest-params
                window.FreshworksWidget.q.push(arguments);
            };
            window.FreshworksWidget.q = [];
        }

        // hide until signed in
        window.FreshworksWidget('hide', 'launcher');
    }

    static loadFreshdesk() {
        if (this.isLoaded) return;
        this.isLoaded = true;

        // inject Freshworks
        const script = document.createElement('script');
        script.src = 'https://widget.freshworks.com/widgets/150000004176.js';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
    }

    static configure(user: IIdentityResponse, sessionUrl?: string) {
        this.loadFreshdesk();

        const FreshworksWidget = window.FreshworksWidget;
        FreshworksWidget('identify', 'ticketForm', {
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
            custom_fields: {
                cf_session_id: sessionUrl,
                cf_context_url: location.href
            }
        });
        FreshworksWidget('hide', 'ticketForm', ['name', 'email', 'custom_fields.cf_session_id', 'custom_fields.cf_context_url', 'product_id']);
    }
}
