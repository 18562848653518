/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IInventoryCategory } from '../models/IInventoryCategory';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class InventoryCategoriesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns IInventoryCategory
     * @throws ApiError
     */
    public getInventoryCategoriesIndex(): CancelablePromise<Array<IInventoryCategory>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/inventory/categories',
        });
    }

    /**
     * @returns IInventoryCategory
     * @throws ApiError
     */
    public postInventoryCategoriesCreate({
        requestBody,
    }: {
        requestBody: {
            name: string;
        },
    }): CancelablePromise<IInventoryCategory> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/inventory/categories',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
