/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { AccountsService } from './services/AccountsService';
import { AuthService } from './services/AuthService';
import { HealthcheckService } from './services/HealthcheckService';
import { IdentityService } from './services/IdentityService';
import { InstallsService } from './services/InstallsService';
import { LoginService } from './services/LoginService';
import { OidcDiscoveryService } from './services/OidcDiscoveryService';
import { PermissionsService } from './services/PermissionsService';
import { ProxyOidcService } from './services/ProxyOidcService';
import { PusherAuthService } from './services/PusherAuthService';
import { TenantAccountService } from './services/TenantAccountService';
import { TokenRpcService } from './services/TokenRpcService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class ApiClient {

    public readonly accounts: AccountsService;
    public readonly auth: AuthService;
    public readonly healthcheck: HealthcheckService;
    public readonly identity: IdentityService;
    public readonly installs: InstallsService;
    public readonly login: LoginService;
    public readonly oidcDiscovery: OidcDiscoveryService;
    public readonly permissions: PermissionsService;
    public readonly proxyOidc: ProxyOidcService;
    public readonly pusherAuth: PusherAuthService;
    public readonly tenantAccount: TenantAccountService;
    public readonly tokenRpc: TokenRpcService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '0.0.1',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.accounts = new AccountsService(this.request);
        this.auth = new AuthService(this.request);
        this.healthcheck = new HealthcheckService(this.request);
        this.identity = new IdentityService(this.request);
        this.installs = new InstallsService(this.request);
        this.login = new LoginService(this.request);
        this.oidcDiscovery = new OidcDiscoveryService(this.request);
        this.permissions = new PermissionsService(this.request);
        this.proxyOidc = new ProxyOidcService(this.request);
        this.pusherAuth = new PusherAuthService(this.request);
        this.tenantAccount = new TenantAccountService(this.request);
        this.tokenRpc = new TokenRpcService(this.request);
    }
}

