import type { ICancelablePromise, IOpenApiClient } from '@signal24/openapi-client-codegen/browser';
import type { Class } from 'type-fest';

import { GlobalState } from '@/global';
import { PusherService } from '@/modules/core/exports/pusher';
import { Auth } from '@/shared/services/auth.service';
import { OpenReplay } from '@/shared/services/openreplay';

import { configureApiClientShared, type IRequestOptions } from './helpers.shared';

export function configureApiClient(
    apiClient: IOpenApiClient,
    CancelablePromise: Class<ICancelablePromise>,
    onRequest?: (options: IRequestOptions) => IRequestOptions
) {
    configureApiClientShared({
        apiClient,
        CancelablePromise,
        getHeaders: getApiRequestHeaders,
        handle401() {
            Auth.clearUserSession();
        },
        onRequest
    });
}

export function getApiRequestHeaders() {
    const authHeader = Auth.activeJwt ? { Authorization: `Bearer ${Auth.activeJwt}` } : {};

    const enableDevAuth = import.meta.env.VITE_APP_API_DEV_AUTH === 'true';

    return {
        ...authHeader,
        ...(enableDevAuth
            ? {
                  'x-zs-aid': GlobalState.user.value?.id,
                  'x-zs-tid': GlobalState.tenant.value?.id,
                  'x-zs-iid': GlobalState.install.value?.id,
                  'x-zs-tam': GlobalState.tenant.value?.isAdmin ? 'true' : undefined
              }
            : {}),
        'x-openreplay-sessionid': OpenReplay.sessionId ?? undefined,
        'x-pusher-cid': PusherService.socketId
    };
}
