/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ISalesSalespoint } from '../models/ISalesSalespoint';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SalesSalespointsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ISalesSalespoint
     * @throws ApiError
     */
    public getSalesSalespointsGetSelf(): CancelablePromise<ISalesSalespoint> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sales/salespoints/self',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public postSalesSalespointsOpenDrawer({
        id,
    }: {
        id: string,
    }): CancelablePromise<{
        ok: boolean;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sales/salespoints/{id}/open-drawer',
            path: {
                'id': id,
            },
        });
    }

}
