/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IDisplayDeviceRegistrationRequest } from '../models/IDisplayDeviceRegistrationRequest';
import type { IDisplayDeviceResponse } from '../models/IDisplayDeviceResponse';
import type { IDisplayDeviceUpdateRequest } from '../models/IDisplayDeviceUpdateRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DisplayDevicesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns IDisplayDeviceResponse
     * @throws ApiError
     */
    public getDisplayDevicesList({
        id,
    }: {
        id?: string,
    }): CancelablePromise<Array<IDisplayDeviceResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/display/devices',
            query: {
                'id': id,
            },
        });
    }

    /**
     * @returns IDisplayDeviceResponse
     * @throws ApiError
     */
    public postDisplayDevicesRegister({
        requestBody,
    }: {
        requestBody: IDisplayDeviceRegistrationRequest,
    }): CancelablePromise<IDisplayDeviceResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/display/devices',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns IDisplayDeviceResponse
     * @throws ApiError
     */
    public putDisplayDevicesUpdateDevice({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: IDisplayDeviceUpdateRequest,
    }): CancelablePromise<IDisplayDeviceResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/display/devices/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
