/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CoreEtlService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns any
     * @throws ApiError
     */
    public postCoreEtlExport({
        redactStripeIds,
    }: {
        redactStripeIds?: 'false',
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/core/etl/export',
            query: {
                'redactStripeIds': redactStripeIds,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public postCoreEtlImport(): CancelablePromise<{
        ok: boolean;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/core/etl/import',
        });
    }

}
