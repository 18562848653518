/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContactShowResponse } from '../models/ContactShowResponse';
import type { ContactUpdateInput } from '../models/ContactUpdateInput';
import type { PickCrmContactEntity } from '../models/PickCrmContactEntity';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CrmContactsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PickCrmContactEntity
     * @throws ApiError
     */
    public getCrmContactsIndex({
        q,
        skip,
    }: {
        q?: string,
        skip?: number,
    }): CancelablePromise<Array<PickCrmContactEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/crm/contacts',
            query: {
                'q': q,
                'skip': skip,
            },
        });
    }

    /**
     * @returns ContactShowResponse
     * @throws ApiError
     */
    public postCrmContactsCreate({
        requestBody,
    }: {
        requestBody: ContactUpdateInput,
    }): CancelablePromise<ContactShowResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/crm/contacts',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ContactShowResponse
     * @throws ApiError
     */
    public getCrmContactsShow({
        id,
    }: {
        id: string,
    }): CancelablePromise<ContactShowResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/crm/contacts/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ContactShowResponse
     * @throws ApiError
     */
    public putCrmContactsUpdate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: ContactUpdateInput,
    }): CancelablePromise<ContactShowResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/crm/contacts/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public deleteCrmContactsDelete({
        id,
    }: {
        id: string,
    }): CancelablePromise<{
        ok: boolean;
    }> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/crm/contacts/{id}',
            path: {
                'id': id,
            },
        });
    }

}
