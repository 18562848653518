/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TokenRpcService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns any
     * @throws ApiError
     */
    public postTokenRpcGenerate({
        requestBody,
    }: {
        requestBody: {
            tenantId: string;
            accountId?: string;
            installId?: string;
            expiresMins?: number;
        },
    }): CancelablePromise<{
        jwt: string;
        expiresAt: number;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/rpc/token/generate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
