/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ISalesMembershipTypeResponse } from '../models/ISalesMembershipTypeResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SalesMembershipTypesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ISalesMembershipTypeResponse
     * @throws ApiError
     */
    public getSalesMembershipTypesIndex(): CancelablePromise<Array<ISalesMembershipTypeResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sales/membership-types',
        });
    }

}
