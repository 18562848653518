import { type Context } from '@opentelemetry/api';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch';
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';
import { Resource } from '@opentelemetry/resources';
import { SimpleSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { BatchSpanProcessor, type Span, WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { SEMRESATTRS_DEPLOYMENT_ENVIRONMENT, SEMRESATTRS_SERVICE_NAME, SEMRESATTRS_SERVICE_VERSION } from '@opentelemetry/semantic-conventions';
import type { Class } from 'type-fest';

export const OtelState: {
    userId?: string;
    tenantId?: string;
    installId?: string;
    openReplaySessionId?: string;
} = {};

// todo: change the state to update here

// Note: For production consider using the "BatchSpanProcessor" to reduce the number of requests
// to your exporter. Using the SimpleSpanProcessor here as it sends the spans immediately to the
// exporter without delay
function createIdentityInjectingSpanProcessor(BaseClass: Class<SimpleSpanProcessor> | Class<BatchSpanProcessor>) {
    return class IdentityInjectingSpanProcessor extends BaseClass {
        onStart(span: Span, context: Context) {
            if (OtelState.tenantId) span.setAttribute('zs.tid', OtelState.tenantId);
            if (OtelState.userId) span.setAttribute('zs.aid', OtelState.userId);
            if (OtelState.installId) span.setAttribute('zs.iid', OtelState.installId);
            if (OtelState.openReplaySessionId) span.setAttribute('openreplay.sid', OtelState.openReplaySessionId);
            super.onStart(span, context);
        }
    } as Class<SimpleSpanProcessor> | Class<BatchSpanProcessor>;
}

const resource = new Resource({
    [SEMRESATTRS_SERVICE_NAME]: 'zynosuite-spa',
    [SEMRESATTRS_SERVICE_VERSION]: import.meta.env.VITE_APP_VERSION,
    [SEMRESATTRS_DEPLOYMENT_ENVIRONMENT]: import.meta.env.MODE
});

const provider = new WebTracerProvider({ resource });

if (import.meta.env.VITE_APP_OTLP_ENDPOINT) {
    const exporter = new OTLPTraceExporter({
        url: import.meta.env.VITE_APP_OTLP_ENDPOINT,
        headers: {} // empty headers object is enough to get it to use XHR instead of beacon
    });
    const ProcessorClass = createIdentityInjectingSpanProcessor(import.meta.env.DEV ? SimpleSpanProcessor : BatchSpanProcessor);
    const processor = new ProcessorClass(exporter);

    provider.addSpanProcessor(processor);
    provider.register({
        contextManager: new ZoneContextManager()
    });

    const ignoreUrls = [/freshworks.com/, 'openreplay.s24.io', 'sentry.s24.dev', /version\.json/];
    const propagateTraceHeaderCorsUrls = [/localhost/, /zynoapi.net/, /^http:\/\/10\./];

    registerInstrumentations({
        tracerProvider: provider,
        instrumentations: [
            new FetchInstrumentation({
                ignoreUrls,
                propagateTraceHeaderCorsUrls,
                clearTimingResources: true
            }),
            new XMLHttpRequestInstrumentation({
                ignoreUrls,
                propagateTraceHeaderCorsUrls
            })
        ]
    });
}

export const tracer = provider.getTracer('default');
