/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { CdrService } from './services/CdrService';
import { FaxesService } from './services/FaxesService';
import { FaxesWebhookService } from './services/FaxesWebhookService';
import { HealthcheckService } from './services/HealthcheckService';
import { InboundFlowrouteSmsService } from './services/InboundFlowrouteSmsService';
import { InboundOitSmsService } from './services/InboundOitSmsService';
import { InboundTelnyxSmsService } from './services/InboundTelnyxSmsService';
import { NumbersService } from './services/NumbersService';
import { OitNotificationService } from './services/OitNotificationService';
import { RecordingService } from './services/RecordingService';
import { SmsConversationsService } from './services/SmsConversationsService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class ApiClient {

    public readonly cdr: CdrService;
    public readonly faxes: FaxesService;
    public readonly faxesWebhook: FaxesWebhookService;
    public readonly healthcheck: HealthcheckService;
    public readonly inboundFlowrouteSms: InboundFlowrouteSmsService;
    public readonly inboundOitSms: InboundOitSmsService;
    public readonly inboundTelnyxSms: InboundTelnyxSmsService;
    public readonly numbers: NumbersService;
    public readonly oitNotification: OitNotificationService;
    public readonly recording: RecordingService;
    public readonly smsConversations: SmsConversationsService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '0.0.1',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.cdr = new CdrService(this.request);
        this.faxes = new FaxesService(this.request);
        this.faxesWebhook = new FaxesWebhookService(this.request);
        this.healthcheck = new HealthcheckService(this.request);
        this.inboundFlowrouteSms = new InboundFlowrouteSmsService(this.request);
        this.inboundOitSms = new InboundOitSmsService(this.request);
        this.inboundTelnyxSms = new InboundTelnyxSmsService(this.request);
        this.numbers = new NumbersService(this.request);
        this.oitNotification = new OitNotificationService(this.request);
        this.recording = new RecordingService(this.request);
        this.smsConversations = new SmsConversationsService(this.request);
    }
}

