/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ISendReceiptRequest } from '../models/ISendReceiptRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SalesTabReceiptsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns any
     * @throws ApiError
     */
    public getSalesTabReceiptsGetAuthenticatedReceipt({
        tabId,
    }: {
        tabId: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sales/tabs/{tabId}/receipt',
            path: {
                'tabId': tabId,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public postSalesTabReceiptsSendReceipt({
        tabId,
        requestBody,
    }: {
        tabId: string,
        requestBody: ISendReceiptRequest,
    }): CancelablePromise<{
        ok: boolean;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sales/tabs/{tabId}/receipt',
            path: {
                'tabId': tabId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public getSalesTabReceiptsGetPublicReceiptByToken({
        token,
    }: {
        token: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/sales/tabs/by-token/receipt',
            query: {
                'token': token,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public getSalesTabReceiptsGetPublicReceipt({
        tabId,
        tenantId,
        dt,
    }: {
        tabId: string,
        tenantId: string,
        dt: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/sales/tabs/{tabId}/receipt',
            path: {
                'tabId': tabId,
            },
            query: {
                'tenantId': tenantId,
                'dt': dt,
            },
        });
    }

}
