/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ISmsConversationResponse } from '../models/ISmsConversationResponse';
import type { ISmsMessageContinueConversationRequest } from '../models/ISmsMessageContinueConversationRequest';
import type { ISmsMessageNewConversationRequest } from '../models/ISmsMessageNewConversationRequest';
import type { ISmsMessageResponse } from '../models/ISmsMessageResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SmsConversationsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ISmsConversationResponse
     * @throws ApiError
     */
    public getSmsConversationsListConversations({
        numberId,
        id,
        q,
        skip,
        limit,
    }: {
        numberId?: string,
        id?: string,
        q?: string,
        skip?: number,
        limit?: number,
    }): CancelablePromise<Array<ISmsConversationResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sms/conversations',
            query: {
                'numberId': numberId,
                'id': id,
                'q': q,
                'skip': skip,
                'limit': limit,
            },
        });
    }

    /**
     * @returns ISmsConversationResponse
     * @throws ApiError
     */
    public postSmsConversationsNewConversation({
        requestBody,
    }: {
        requestBody: ISmsMessageNewConversationRequest,
    }): CancelablePromise<ISmsConversationResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sms/conversations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ISmsConversationResponse
     * @throws ApiError
     */
    public getSmsConversationsGetConversation({
        id,
    }: {
        id: string,
    }): CancelablePromise<ISmsConversationResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sms/conversations/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ISmsMessageResponse
     * @throws ApiError
     */
    public getSmsConversationsGetMessages({
        conversationId,
        afterId,
        beforeId,
        limit,
    }: {
        conversationId: string,
        afterId?: string,
        beforeId?: string,
        limit?: number,
    }): CancelablePromise<Array<ISmsMessageResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sms/conversations/{conversationId}/messages',
            path: {
                'conversationId': conversationId,
            },
            query: {
                'afterId': afterId,
                'beforeId': beforeId,
                'limit': limit,
            },
        });
    }

    /**
     * @returns ISmsMessageResponse
     * @throws ApiError
     */
    public postSmsConversationsContinueConversation({
        conversationId,
        requestBody,
    }: {
        conversationId: string,
        requestBody: ISmsMessageContinueConversationRequest,
    }): CancelablePromise<ISmsMessageResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sms/conversations/{conversationId}/messages',
            path: {
                'conversationId': conversationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public postSmsConversationsMarkMessageRead({
        conversationId,
        messageId,
    }: {
        conversationId: string,
        messageId: string,
    }): CancelablePromise<{
        ok: boolean;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sms/conversations/{conversationId}/messages/{messageId}/read',
            path: {
                'conversationId': conversationId,
                'messageId': messageId,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public postSmsConversationsMarkMessageUnread({
        conversationId,
        messageId,
    }: {
        conversationId: string,
        messageId: string,
    }): CancelablePromise<{
        ok: boolean;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sms/conversations/{conversationId}/messages/{messageId}/unread',
            path: {
                'conversationId': conversationId,
                'messageId': messageId,
            },
        });
    }

}
