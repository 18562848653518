import { defineStore } from 'pinia';

export const useStore = defineStore('root', {
    state: () => ({
        globalLoader: false as boolean,
        globalError: null as string | null,
        rootAppName: null as string | null,
        subAppName: null as string | null,
        titleOverride: null as string | null
    }),

    getters: {
        // globalState() {
        //     return GlobalState;
        // }
    }
});
