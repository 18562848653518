/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ILoginResponse } from '../models/ILoginResponse';
import type { Redirect } from '../models/Redirect';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LoginService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns any
     * @throws ApiError
     */
    public postLoginLogin({
        requestBody,
    }: {
        requestBody: {
            email: string;
            redirectUrl?: string;
            intendedUrl?: string;
            proxy?: boolean;
        },
    }): CancelablePromise<{
        next: 'username' | 'password' | 'oauth';
        authorizeUrl?: string;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/login',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ILoginResponse
     * @throws ApiError
     */
    public postLoginPasswordLogin({
        requestBody,
    }: {
        requestBody: {
            email: string;
            password: string;
        },
    }): CancelablePromise<ILoginResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/login/password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ILoginResponse
     * @throws ApiError
     */
    public postLoginSsoCallback({
        requestBody,
    }: {
        requestBody: {
            redirectUrl: string;
            code?: string;
            error?: string;
            state: string;
            preferredTenantId?: string;
        },
    }): CancelablePromise<ILoginResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/login/sso',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Redirect
     * @throws ApiError
     */
    public getLoginSsoDirectCallback({
        state,
        code,
        error,
    }: {
        state: string,
        code?: string,
        error?: string,
    }): CancelablePromise<Redirect> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/login/sso-callback',
            query: {
                'code': code,
                'error': error,
                'state': state,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public postLoginRenew(): CancelablePromise<{
        jwt: string;
        expiresAt: number;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/login/renew',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public postLoginSwitchTenant({
        requestBody,
    }: {
        requestBody: {
            tenantId: string;
        },
    }): CancelablePromise<{
        jwt: string;
        expiresAt: number;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/login/switch-tenant',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
