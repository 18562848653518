/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ISalesFinancialAccount } from '../models/ISalesFinancialAccount';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SalesFinancialAccountsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ISalesFinancialAccount
     * @throws ApiError
     */
    public getSalesFinancialAccountsIndex(): CancelablePromise<Array<ISalesFinancialAccount>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sales/financial/accounts',
        });
    }

    /**
     * @returns ISalesFinancialAccount
     * @throws ApiError
     */
    public postSalesFinancialAccountsCreate({
        requestBody,
    }: {
        requestBody: {
            name: string;
        },
    }): CancelablePromise<ISalesFinancialAccount> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sales/financial/accounts',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
