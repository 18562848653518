/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PusherAuthService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns any
     * @throws ApiError
     */
    public postPusherAuthAuth({
        requestBody,
    }: {
        requestBody: {
            socket_id: string;
            channel_name: string;
        },
    }): CancelablePromise<{
        auth: string;
        channel_data?: string;
        shared_secret?: string;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/pusher-auth',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
