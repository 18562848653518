/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CrmNoteCreateInput } from '../models/CrmNoteCreateInput';
import type { CrmNoteResponse } from '../models/CrmNoteResponse';
import type { CrmNoteUpdateInput } from '../models/CrmNoteUpdateInput';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CrmContactNotesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns CrmNoteResponse
     * @throws ApiError
     */
    public getCrmContactNotesIndex({
        contactId,
    }: {
        contactId: string,
    }): CancelablePromise<Array<CrmNoteResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/crm/contacts/{contactId}/notes',
            path: {
                'contactId': contactId,
            },
        });
    }

    /**
     * @returns CrmNoteResponse
     * @throws ApiError
     */
    public postCrmContactNotesCreate({
        contactId,
        requestBody,
    }: {
        contactId: string,
        requestBody: CrmNoteCreateInput,
    }): CancelablePromise<CrmNoteResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/crm/contacts/{contactId}/notes',
            path: {
                'contactId': contactId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CrmNoteResponse
     * @throws ApiError
     */
    public putCrmContactNotesUpdate({
        contactId,
        id,
        requestBody,
    }: {
        contactId: string,
        id: string,
        requestBody: CrmNoteUpdateInput,
    }): CancelablePromise<CrmNoteResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/crm/contacts/{contactId}/notes/{id}',
            path: {
                'contactId': contactId,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public deleteCrmContactNotesDelete({
        contactId,
        id,
    }: {
        contactId: string,
        id: string,
    }): CancelablePromise<{
        ok: boolean;
    }> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/crm/contacts/{contactId}/notes/{id}',
            path: {
                'contactId': contactId,
                'id': id,
            },
        });
    }

}
