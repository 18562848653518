/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class InboundTelnyxSmsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns any
     * @throws ApiError
     */
    public postInboundTelnyxSmsHandle({
        requestBody,
    }: {
        requestBody: {
            data: {
                event_type: string;
                payload: {
                    id: string;
                    from: {
                        phone_number: string;
                    };
                    to: Array<{
                        phone_number: string;
                    }>;
                    media?: Array<{
                        url: string;
                    }>;
                    text: string;
                };
            };
        },
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/public/sms/inbound/telnyx',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
