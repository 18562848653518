/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ISalesGiftCardDeliveryInfo } from '../models/ISalesGiftCardDeliveryInfo';
import type { ISalesGiftCardResponse } from '../models/ISalesGiftCardResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SalesGiftCardsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ISalesGiftCardResponse
     * @throws ApiError
     */
    public getSalesGiftCardsIndex({
        beforeId,
        limit,
        q,
    }: {
        beforeId?: string,
        limit?: number,
        q?: string,
    }): CancelablePromise<Array<ISalesGiftCardResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sales/gift-cards',
            query: {
                'beforeId': beforeId,
                'limit': limit,
                'q': q,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public getSalesGiftCardsLookup({
        number,
    }: {
        number: string,
    }): CancelablePromise<{
        giftCard: (ISalesGiftCardResponse | null);
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sales/gift-cards/lookup',
            query: {
                'number': number,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public postSalesGiftCardsDeliver({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: ISalesGiftCardDeliveryInfo,
    }): CancelablePromise<{
        deliveryId: string;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sales/gift-cards/{id}/deliver',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
