/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IWebhookPayload } from '../models/IWebhookPayload';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FaxesWebhookService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns any
     * @throws ApiError
     */
    public postFaxesWebhookHandlePayload({
        requestBody,
    }: {
        requestBody: IWebhookPayload,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/public/faxes/webhook',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
