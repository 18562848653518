/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IFormSubmissionResponse } from '../models/IFormSubmissionResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FormSubmissionsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns IFormSubmissionResponse
     * @throws ApiError
     */
    public getFormSubmissionsGetSubmissions({
        id,
        beforeId,
        limit,
        date,
        afterDate,
        beforeDate,
        formId,
        crmContactId,
    }: {
        id?: string,
        beforeId?: string,
        limit?: number,
        date?: string,
        afterDate?: string,
        beforeDate?: string,
        formId?: string,
        crmContactId?: string,
    }): CancelablePromise<Array<IFormSubmissionResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/forms/submissions',
            query: {
                'id': id,
                'beforeId': beforeId,
                'limit': limit,
                'date': date,
                'afterDate': afterDate,
                'beforeDate': beforeDate,
                'formId': formId,
                'crmContactId': crmContactId,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public getFormSubmissionsGetSubmissionRenderHtml({
        id,
    }: {
        id: string,
    }): CancelablePromise<{
        html: string;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/forms/submissions/{id}/render-html',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public getFormSubmissionsGetSubmissionRenderPdf({
        id,
    }: {
        id: string,
    }): CancelablePromise<{
        pdf: string;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/forms/submissions/{id}/render-pdf',
            path: {
                'id': id,
            },
        });
    }

}
