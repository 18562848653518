/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ISalesProductCategory } from '../models/ISalesProductCategory';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SalesConfigProductCategoriesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ISalesProductCategory
     * @throws ApiError
     */
    public getSalesConfigProductCategoriesIndex(): CancelablePromise<Array<ISalesProductCategory>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sales/config/categories',
        });
    }

    /**
     * @returns ISalesProductCategory
     * @throws ApiError
     */
    public postSalesConfigProductCategoriesCreate({
        requestBody,
    }: {
        requestBody: {
            name: string;
        },
    }): CancelablePromise<ISalesProductCategory> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sales/config/categories',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ISalesProductCategory
     * @throws ApiError
     */
    public putSalesConfigProductCategoriesUpdate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: {
            name: string;
        },
    }): CancelablePromise<ISalesProductCategory> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/sales/config/categories/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public deleteSalesConfigProductCategoriesDelete({
        id,
    }: {
        id: string,
    }): CancelablePromise<{
        ok: boolean;
    }> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/sales/config/categories/{id}',
            path: {
                'id': id,
            },
        });
    }

}
