/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IFormResponse } from '../models/IFormResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FormsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns IFormResponse
     * @throws ApiError
     */
    public getFormsListForms({
        id,
    }: {
        id?: string,
    }): CancelablePromise<Array<IFormResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/forms',
            query: {
                'id': id,
            },
        });
    }

}
