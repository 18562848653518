/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ISalesFinancialTransactionResponse } from '../models/ISalesFinancialTransactionResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SalesFinancialTransactionsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ISalesFinancialTransactionResponse
     * @throws ApiError
     */
    public getSalesFinancialTransactionsIndex({
        beforeId,
        limit,
        saleNumber,
        date,
        afterDate,
        beforeDate,
        salespersonId,
        installId,
        crmContactId,
        productId,
        paymentMethod,
        cardLast4,
        amount,
    }: {
        beforeId?: string,
        limit?: number,
        saleNumber?: string,
        date?: string,
        afterDate?: string,
        beforeDate?: string,
        salespersonId?: string,
        installId?: string,
        crmContactId?: string,
        productId?: string,
        paymentMethod?: 'card' | 'cash',
        cardLast4?: string,
        amount?: number,
    }): CancelablePromise<Array<ISalesFinancialTransactionResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sales/financial/transactions',
            query: {
                'beforeId': beforeId,
                'limit': limit,
                'saleNumber': saleNumber,
                'date': date,
                'afterDate': afterDate,
                'beforeDate': beforeDate,
                'salespersonId': salespersonId,
                'installId': installId,
                'crmContactId': crmContactId,
                'productId': productId,
                'paymentMethod': paymentMethod,
                'cardLast4': cardLast4,
                'amount': amount,
            },
        });
    }

}
