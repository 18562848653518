/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CoreUploadInput } from '../models/CoreUploadInput';
import type { CoreUploadResponse } from '../models/CoreUploadResponse';
import type { PickCoreUploadResponse } from '../models/PickCoreUploadResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CoreUploadsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns CoreUploadResponse
     * @throws ApiError
     */
    public postCoreUploadsUpload({
        requestBody,
    }: {
        requestBody: CoreUploadInput,
    }): CancelablePromise<CoreUploadResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/core/uploads',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PickCoreUploadResponse
     * @throws ApiError
     */
    public postCoreUploadsUploadFromScanner({
        requestBody,
    }: {
        requestBody: {
            type: 'coreAvatar' | 'crmFile';
            requestId: string;
            deviceId?: string;
        },
    }): CancelablePromise<PickCoreUploadResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/core/uploads/from-scanner',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
