/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class InboundFlowrouteSmsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns any
     * @throws ApiError
     */
    public postInboundFlowrouteSmsHandle({
        requestBody,
    }: {
        requestBody: {
            data: {
                type: 'message';
                id: string;
                attributes: {
                    from: string;
                    to: string;
                    body: string;
                    is_mms: boolean;
                };
            };
            included?: Array<{
                type: 'media';
                attributes: {
                    file_name: string;
                    file_size: number;
                    mime_type: string;
                    url: string;
                };
            }>;
        },
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/public/sms/inbound/flowroute',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
