/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IInventoryVendor } from '../models/IInventoryVendor';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class InventoryVendorsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns IInventoryVendor
     * @throws ApiError
     */
    public getInventoryVendorsIndex(): CancelablePromise<Array<IInventoryVendor>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/inventory/vendors',
        });
    }

    /**
     * @returns IInventoryVendor
     * @throws ApiError
     */
    public postInventoryVendorsCreate({
        requestBody,
    }: {
        requestBody: {
            name: string;
        },
    }): CancelablePromise<IInventoryVendor> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/inventory/vendors',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
