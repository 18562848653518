/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IInventoryItem } from '../models/IInventoryItem';
import type { IInventoryItemCreateInput } from '../models/IInventoryItemCreateInput';
import type { IInventoryItemUpdateInput } from '../models/IInventoryItemUpdateInput';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class InventoryItemsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns IInventoryItem
     * @throws ApiError
     */
    public getInventoryItemsIndex(): CancelablePromise<Array<IInventoryItem>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/inventory/items',
        });
    }

    /**
     * @returns IInventoryItem
     * @throws ApiError
     */
    public postInventoryItemsCreate({
        requestBody,
    }: {
        requestBody: IInventoryItemCreateInput,
    }): CancelablePromise<IInventoryItem> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/inventory/items',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns IInventoryItem
     * @throws ApiError
     */
    public getInventoryItemsGet({
        id,
    }: {
        id: string,
    }): CancelablePromise<IInventoryItem> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/inventory/items/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns IInventoryItem
     * @throws ApiError
     */
    public putInventoryItemsUpdate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: IInventoryItemUpdateInput,
    }): CancelablePromise<IInventoryItem> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/inventory/items/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public postInventoryItemsPrintLabel({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: {
            quantity: number;
        },
    }): CancelablePromise<{
        ok: boolean;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/inventory/items/{id}/print-label',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
