/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CallRecordingFormat } from '../models/CallRecordingFormat';
import type { PickCallEntity } from '../models/PickCallEntity';
import type { UploadedFile } from '../models/UploadedFile';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CdrService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PickCallEntity
     * @throws ApiError
     */
    public getCdrGet({
        q,
        sort,
        sortDir,
        skip,
    }: {
        q?: string,
        sort?: 'placedAt' | 'callerIdName' | 'callerIdNumber' | 'destinationNumber' | 'duration',
        sortDir?: 'asc' | 'desc',
        skip?: number,
    }): CancelablePromise<Array<PickCallEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/cdr',
            query: {
                'q': q,
                'sort': sort,
                'sortDir': sortDir,
                'skip': skip,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public postCdrCreate({
        requestBody,
    }: {
        requestBody: {
            callerIdName: string;
            callerIdNumber: string;
            destinationNumber: string;
            placedAt: string;
            duration: number;
            codec: CallRecordingFormat;
            inFile?: UploadedFile;
            outFile?: UploadedFile;
            file?: UploadedFile;
        },
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/cdr',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
