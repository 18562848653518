/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IMembershipDiscountResponse } from '../models/IMembershipDiscountResponse';
import type { IMembershipRenewalResponse } from '../models/IMembershipRenewalResponse';
import type { IMembershipUpdateRequest } from '../models/IMembershipUpdateRequest';
import type { SalesXCrmMembershipResponse } from '../models/SalesXCrmMembershipResponse';
import type { SalesXCrmMembershipWithContactResponse } from '../models/SalesXCrmMembershipWithContactResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SalesXCrmMembershipsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns SalesXCrmMembershipWithContactResponse
     * @throws ApiError
     */
    public getSalesXCrmMembershipsIndex({
        membershipTypeId,
        status,
    }: {
        membershipTypeId?: string,
        status?: 'active' | 'pastDue' | 'terminated',
    }): CancelablePromise<Array<SalesXCrmMembershipWithContactResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sales/x-crm/memberships',
            query: {
                'membershipTypeId': membershipTypeId,
                'status': status,
            },
        });
    }

    /**
     * @returns SalesXCrmMembershipResponse
     * @throws ApiError
     */
    public getSalesXCrmMembershipsContactIndex({
        contactId,
    }: {
        contactId: string,
    }): CancelablePromise<Array<SalesXCrmMembershipResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sales/x-crm/{contactId}/memberships',
            path: {
                'contactId': contactId,
            },
        });
    }

    /**
     * @returns SalesXCrmMembershipResponse
     * @throws ApiError
     */
    public putSalesXCrmMembershipsUpdate({
        contactId,
        id,
        requestBody,
    }: {
        contactId: string,
        id: string,
        requestBody: IMembershipUpdateRequest,
    }): CancelablePromise<SalesXCrmMembershipResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/sales/x-crm/{contactId}/memberships/{id}',
            path: {
                'contactId': contactId,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public postSalesXCrmMembershipsPreviewChange({
        contactId,
        id,
        requestBody,
    }: {
        contactId: string,
        id: string,
        requestBody: {
            membershipTypeId: string;
            subscriptionRenewalPrice: number;
        },
    }): CancelablePromise<{
        conversionCost: number;
        periodEnd: string;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sales/x-crm/{contactId}/memberships/{id}/preview-change',
            path: {
                'contactId': contactId,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns IMembershipRenewalResponse
     * @throws ApiError
     */
    public getSalesXCrmMembershipsGetRenewals({
        contactId,
        id,
    }: {
        contactId: string,
        id: string,
    }): CancelablePromise<Array<IMembershipRenewalResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sales/x-crm/{contactId}/memberships/{id}/renewals',
            path: {
                'contactId': contactId,
                'id': id,
            },
        });
    }

    /**
     * @returns IMembershipDiscountResponse
     * @throws ApiError
     */
    public getSalesXCrmMembershipsGetDiscountDetail({
        contactId,
        id,
    }: {
        contactId: string,
        id: string,
    }): CancelablePromise<IMembershipDiscountResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sales/x-crm/{contactId}/memberships/{id}/discounts',
            path: {
                'contactId': contactId,
                'id': id,
            },
        });
    }

}
