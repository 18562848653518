/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CrmPaymentMethodResponse } from '../models/CrmPaymentMethodResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CrmContactPaymentMethodsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns CrmPaymentMethodResponse
     * @throws ApiError
     */
    public getCrmContactPaymentMethodsIndex({
        contactId,
    }: {
        contactId: string,
    }): CancelablePromise<Array<CrmPaymentMethodResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/crm/contacts/{contactId}/payment-methods',
            path: {
                'contactId': contactId,
            },
        });
    }

    /**
     * @returns CrmPaymentMethodResponse
     * @throws ApiError
     */
    public postCrmContactPaymentMethodsCreate({
        contactId,
        requestBody,
    }: {
        contactId: string,
        requestBody: {
            setupIntentId: string;
        },
    }): CancelablePromise<CrmPaymentMethodResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/crm/contacts/{contactId}/payment-methods',
            path: {
                'contactId': contactId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public postCrmContactPaymentMethodsSetup({
        contactId,
    }: {
        contactId: string,
    }): CancelablePromise<{
        clientSecret: string;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/crm/contacts/{contactId}/payment-methods/setup',
            path: {
                'contactId': contactId,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public postCrmContactPaymentMethodsCreateViaTerminal({
        contactId,
        requestBody,
    }: {
        contactId: string,
        requestBody: {
            terminalId: string;
        },
    }): CancelablePromise<{
        ok: boolean;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/crm/contacts/{contactId}/payment-methods/setup/via-terminal',
            path: {
                'contactId': contactId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public deleteCrmContactPaymentMethodsDelete({
        contactId,
        id,
    }: {
        contactId: string,
        id: string,
    }): CancelablePromise<{
        ok: boolean;
    }> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/crm/contacts/{contactId}/payment-methods/{id}',
            path: {
                'contactId': contactId,
                'id': id,
            },
        });
    }

}
