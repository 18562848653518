/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class OitNotificationService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns any
     * @throws ApiError
     */
    public postOitNotificationPost({
        accountId,
        requestBody,
    }: {
        accountId: string,
        requestBody: Array<{
            orig_callid: string;
            orig_domain: string;
            term_domain: string;
            time_start: string;
            remove?: 'yes';
        }>,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/public/byoc/oit/notification/{accountId}',
            path: {
                'accountId': accountId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
