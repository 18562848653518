/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ISalesReportsResponse } from '../models/ISalesReportsResponse';
import type { SalesReportTimeGrouping } from '../models/SalesReportTimeGrouping';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SalesReportsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ISalesReportsResponse
     * @throws ApiError
     */
    public postSalesReportsGetData({
        requestBody,
    }: {
        requestBody: {
            productIds?: Array<string>;
            categoryIds?: Array<string>;
            tagIds?: Array<string>;
            dateStart: string;
            dateEnd: string;
            groupBy: SalesReportTimeGrouping;
            tz: string;
        },
    }): CancelablePromise<ISalesReportsResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sales/reports/data',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
