/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CrmFileCreateInput } from '../models/CrmFileCreateInput';
import type { CrmFileResponse } from '../models/CrmFileResponse';
import type { CrmFileUpdateInput } from '../models/CrmFileUpdateInput';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CrmContactFilesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns CrmFileResponse
     * @throws ApiError
     */
    public getCrmContactFilesIndex({
        contactId,
    }: {
        contactId: string,
    }): CancelablePromise<Array<CrmFileResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/crm/contacts/{contactId}/files',
            path: {
                'contactId': contactId,
            },
        });
    }

    /**
     * @returns CrmFileResponse
     * @throws ApiError
     */
    public postCrmContactFilesCreate({
        contactId,
        requestBody,
    }: {
        contactId: string,
        requestBody: CrmFileCreateInput,
    }): CancelablePromise<CrmFileResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/crm/contacts/{contactId}/files',
            path: {
                'contactId': contactId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public getCrmContactFilesDownload({
        contactId,
        id,
    }: {
        contactId: string,
        id: string,
    }): CancelablePromise<{
        url: string;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/crm/contacts/{contactId}/files/{id}/download',
            path: {
                'contactId': contactId,
                'id': id,
            },
        });
    }

    /**
     * @returns CrmFileResponse
     * @throws ApiError
     */
    public putCrmContactFilesUpdate({
        contactId,
        id,
        requestBody,
    }: {
        contactId: string,
        id: string,
        requestBody: CrmFileUpdateInput,
    }): CancelablePromise<CrmFileResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/crm/contacts/{contactId}/files/{id}',
            path: {
                'contactId': contactId,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public deleteCrmContactFilesDelete({
        contactId,
        id,
    }: {
        contactId: string,
        id: string,
    }): CancelablePromise<{
        ok: boolean;
    }> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/crm/contacts/{contactId}/files/{id}',
            path: {
                'contactId': contactId,
                'id': id,
            },
        });
    }

}
