/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IFreshdeskBridgeConfigResponse } from '../models/IFreshdeskBridgeConfigResponse';
import type { RecordStringString } from '../models/RecordStringString';
import type { Redirect } from '../models/Redirect';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ItPublicFreshdeskBridgeService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns IFreshdeskBridgeConfigResponse
     * @throws ApiError
     */
    public getItPublicFreshdeskBridgeGetConfig({
        tenantId,
        jwt,
    }: {
        tenantId: string,
        jwt?: string,
    }): CancelablePromise<IFreshdeskBridgeConfigResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/it/freshdesk/bridge/config',
            query: {
                'tenantId': tenantId,
                'jwt': jwt,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public postItPublicFreshdeskBridgeSubmitForm({
        requestBody,
    }: {
        requestBody: {
            tenantId: string;
            jwt: string;
            fields: RecordStringString;
        },
    }): CancelablePromise<{
        ok: boolean;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/public/it/freshdesk/bridge/submit',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Redirect
     * @throws ApiError
     */
    public getItPublicFreshdeskBridgeStartSso({
        tenantId,
    }: {
        tenantId: string,
    }): CancelablePromise<Redirect> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/it/freshdesk/bridge/sso/start',
            query: {
                'tenantId': tenantId,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public postItPublicFreshdeskBridgeGenerateSsoToken({
        requestBody,
    }: {
        requestBody: {
            tenantId: string;
            jwt: string;
            nonce: string;
        },
    }): CancelablePromise<{
        token: string;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/public/it/freshdesk/bridge/sso/generate-token',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
