/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IDetailedSalesTab } from '../models/IDetailedSalesTab';
import type { IPriceOverride } from '../models/IPriceOverride';
import type { ISalesTab } from '../models/ISalesTab';
import type { ISalesTabItemMetadata } from '../models/ISalesTabItemMetadata';
import type { ISalesTabUpdateRequest } from '../models/ISalesTabUpdateRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SalesTabsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ISalesTab
     * @throws ApiError
     */
    public getSalesTabsList({
        crmContactId,
        skip,
        limit,
        status,
    }: {
        crmContactId?: string,
        skip?: number,
        limit?: number,
        status?: 'open' | 'closed' | 'voided',
    }): CancelablePromise<Array<ISalesTab>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sales/tabs',
            query: {
                'crmContactId': crmContactId,
                'skip': skip,
                'limit': limit,
                'status': status,
            },
        });
    }

    /**
     * @returns IDetailedSalesTab
     * @throws ApiError
     */
    public getSalesTabsGet({
        tabId,
    }: {
        tabId: string,
    }): CancelablePromise<IDetailedSalesTab> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sales/tabs/{tabId}',
            path: {
                'tabId': tabId,
            },
        });
    }

    /**
     * @returns IDetailedSalesTab
     * @throws ApiError
     */
    public putSalesTabsUpdateTab({
        tabId,
        requestBody,
    }: {
        tabId: string,
        requestBody: ISalesTabUpdateRequest,
    }): CancelablePromise<IDetailedSalesTab> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/sales/tabs/{tabId}',
            path: {
                'tabId': tabId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public postSalesTabsTouch({
        tabId,
        requestBody,
    }: {
        tabId: string,
        requestBody: {
            lockKey: string;
            overtake?: boolean;
        },
    }): CancelablePromise<{
        ok: boolean;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sales/tabs/{tabId}/touch',
            path: {
                'tabId': tabId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns IDetailedSalesTab
     * @throws ApiError
     */
    public postSalesTabsCreateItem({
        tabId,
        requestBody,
    }: {
        tabId: string,
        requestBody: {
            lockKey: string;
            productId: string;
            initialPrice?: number;
            notes?: (string | null);
            metadata?: ISalesTabItemMetadata;
        },
    }): CancelablePromise<IDetailedSalesTab> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sales/tabs/{tabId}/items',
            path: {
                'tabId': tabId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns IDetailedSalesTab
     * @throws ApiError
     */
    public putSalesTabsUpdateItem({
        tabId,
        itemId,
        requestBody,
    }: {
        tabId: string,
        itemId: string,
        requestBody: {
            lockKey: string;
            qty?: number;
            crmContactId?: (string | null);
            initialPrice?: number;
            priceOverride?: (IPriceOverride | null);
            subscriptionPriceOverride?: (IPriceOverride | null);
            notes?: (string | null);
            metadata?: ISalesTabItemMetadata;
        },
    }): CancelablePromise<IDetailedSalesTab> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/sales/tabs/{tabId}/items/{itemId}',
            path: {
                'tabId': tabId,
                'itemId': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns IDetailedSalesTab
     * @throws ApiError
     */
    public deleteSalesTabsDeleteItem({
        tabId,
        itemId,
        requestBody,
    }: {
        tabId: string,
        itemId: string,
        requestBody: {
            lockKey: string;
        },
    }): CancelablePromise<IDetailedSalesTab> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/sales/tabs/{tabId}/items/{itemId}',
            path: {
                'tabId': tabId,
                'itemId': itemId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
