/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ISalesProduct } from '../models/ISalesProduct';
import type { ISalesProductCreateInput } from '../models/ISalesProductCreateInput';
import type { ISalesProductUpdateInput } from '../models/ISalesProductUpdateInput';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SalesProductsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ISalesProduct
     * @throws ApiError
     */
    public getSalesProductsIndex(): CancelablePromise<Array<ISalesProduct>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sales/products',
        });
    }

    /**
     * @returns ISalesProduct
     * @throws ApiError
     */
    public postSalesProductsCreate({
        requestBody,
    }: {
        requestBody: ISalesProductCreateInput,
    }): CancelablePromise<ISalesProduct> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sales/products',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ISalesProduct
     * @throws ApiError
     */
    public getSalesProductsShow({
        id,
    }: {
        id: string,
    }): CancelablePromise<ISalesProduct> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sales/products/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ISalesProduct
     * @throws ApiError
     */
    public putSalesProductsUpdate({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: ISalesProductUpdateInput,
    }): CancelablePromise<ISalesProduct> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/sales/products/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public deleteSalesProductsDelete({
        id,
    }: {
        id: string,
    }): CancelablePromise<{
        ok: boolean;
    }> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/sales/products/{id}',
            path: {
                'id': id,
            },
        });
    }

}
