import { computed } from 'vue';
import { type RouteMeta } from 'vue-router';

import { GlobalState } from '@/global';
import type { IZynoPermissions } from '@/openapi-clients-generated/auth';

export function makePermissionMeta<A extends keyof IZynoPermissions, F extends keyof NonNullable<IZynoPermissions[A]>>(
    app: A,
    feature?: F | F[]
): RouteMeta {
    return { app, feature: feature as string | string[] | undefined };
}

export function hasPermission<A extends keyof IZynoPermissions, F extends keyof NonNullable<IZynoPermissions[A]>>(
    app: A,
    feature?: F | F[]
): boolean {
    if (!GlobalState.permissions.value) return false;
    if (!GlobalState.permissions.value[app]) return false;
    if (!feature) return true;
    if (Array.isArray(feature)) {
        return feature.some(f => GlobalState.permissions.value![app]![f] === true);
    } else {
        return GlobalState.permissions.value[app]![feature] === true;
    }
}

export const routesWithAuthorization = computed(() => {
    const result = GlobalState.router
        .getRoutes()
        .filter(r => r.children.length === 0) /* child routes contain the permissions */
        .map(r => ({
            path: r.path,
            pathRe: new RegExp(`^${r.path.replace(/:[^/]+/g, '.+')}$`),
            redirect: r.redirect,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            authorized: r.redirect ? null : r.meta.app === undefined || hasPermission(r.meta.app as any, r.meta.feature as any)
        }));
    result
        .filter(r => r.redirect)
        .forEach(r => {
            r.authorized = result.find(o => o.path === r.redirect)?.authorized ?? false;
        });
    return result;
});

export function hasRouteAccess(path: string) {
    const matchingRoute = routesWithAuthorization.value.find(r => r.pathRe.test(path));
    return matchingRoute?.authorized === true;
}
