/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IIntegrationCreateRequest } from '../models/IIntegrationCreateRequest';
import type { IIntegrationQboAccount } from '../models/IIntegrationQboAccount';
import type { IIntegrationResponse } from '../models/IIntegrationResponse';
import type { QboIntegrationUpdate } from '../models/QboIntegrationUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SalesIntegrationsQboConnectionsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns IIntegrationResponse
     * @throws ApiError
     */
    public getSalesIntegrationsQboConnectionsGetIntegrations(): CancelablePromise<IIntegrationResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sales/integrations/qbo/connections',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public postSalesIntegrationsQboConnectionsCreateIntegration({
        requestBody,
    }: {
        requestBody: IIntegrationCreateRequest,
    }): CancelablePromise<{
        ok: boolean;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sales/integrations/qbo/connections',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public putSalesIntegrationsQboConnectionsUpdateIntegration({
        requestBody,
    }: {
        requestBody: QboIntegrationUpdate,
    }): CancelablePromise<{
        ok: boolean;
    }> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/sales/integrations/qbo/connections',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public deleteSalesIntegrationsQboConnectionsDeleteIntegration(): CancelablePromise<{
        ok: boolean;
    }> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/sales/integrations/qbo/connections',
        });
    }

    /**
     * @returns IIntegrationQboAccount
     * @throws ApiError
     */
    public getSalesIntegrationsQboConnectionsGetQboAccounts(): CancelablePromise<Array<IIntegrationQboAccount>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sales/integrations/qbo/connections/qb-accounts',
        });
    }

}
