/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IDetailedSalesTab } from '../models/IDetailedSalesTab';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SalesTabPaymentsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns any
     * @throws ApiError
     */
    public postSalesTabPaymentsSetupCcPaymentIntent({
        tabId,
        requestBody,
    }: {
        tabId: string,
        requestBody: {
            amount: number;
        },
    }): CancelablePromise<{
        clientSecret: string;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sales/tabs/{tabId}/payments/setup-cc',
            path: {
                'tabId': tabId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public postSalesTabPaymentsSetupCcTerminalPaymentIntent({
        tabId,
        requestBody,
    }: {
        tabId: string,
        requestBody: {
            amount: number;
            terminalId: string;
        },
    }): CancelablePromise<{
        ok: boolean;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sales/tabs/{tabId}/payments/setup-cc-terminal',
            path: {
                'tabId': tabId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns IDetailedSalesTab
     * @throws ApiError
     */
    public postSalesTabPaymentsConfirmPaymentIntent({
        tabId,
        requestBody,
    }: {
        tabId: string,
        requestBody: {
            paymentIntentId: string;
        },
    }): CancelablePromise<IDetailedSalesTab> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sales/tabs/{tabId}/payments/confirm-payment-intent',
            path: {
                'tabId': tabId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns IDetailedSalesTab
     * @throws ApiError
     */
    public postSalesTabPaymentsPayWithMethod({
        tabId,
        requestBody,
    }: {
        tabId: string,
        requestBody: {
            paymentMethodId: string;
            amount: number;
        },
    }): CancelablePromise<IDetailedSalesTab> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sales/tabs/{tabId}/payments/pay-with-method',
            path: {
                'tabId': tabId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns IDetailedSalesTab
     * @throws ApiError
     */
    public postSalesTabPaymentsPayWithCash({
        tabId,
        requestBody,
    }: {
        tabId: string,
        requestBody: {
            amount: number;
            cashProvided: number;
        },
    }): CancelablePromise<IDetailedSalesTab> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sales/tabs/{tabId}/payments/pay-with-cash',
            path: {
                'tabId': tabId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns IDetailedSalesTab
     * @throws ApiError
     */
    public postSalesTabPaymentsPayWithGiftCard({
        tabId,
        requestBody,
    }: {
        tabId: string,
        requestBody: {
            amount: number;
            cardNumber: string;
        },
    }): CancelablePromise<IDetailedSalesTab> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sales/tabs/{tabId}/payments/pay-with-gift-card',
            path: {
                'tabId': tabId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns IDetailedSalesTab
     * @throws ApiError
     */
    public postSalesTabPaymentsFinalizeZeroRemainderTab({
        tabId,
    }: {
        tabId: string,
    }): CancelablePromise<IDetailedSalesTab> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sales/tabs/{tabId}/payments/finalize',
            path: {
                'tabId': tabId,
            },
        });
    }

}
