/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OAuthProvider } from '../models/OAuthProvider';
import type { Redirect } from '../models/Redirect';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProxyOidcService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns any
     * @throws ApiError
     */
    public postProxyOidcGenerateStartUrl({
        requestBody,
    }: {
        requestBody: {
            email?: string;
            provider?: OAuthProvider;
            returnUrl: string;
        },
    }): CancelablePromise<{
        url: string;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/proxy-oidc/generate-start-url',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Redirect
     * @throws ApiError
     */
    public postProxyOidcStart({
        requestBody,
    }: {
        requestBody: {
            email: string;
            provider?: OAuthProvider;
            returnUrl: string;
        },
    }): CancelablePromise<Redirect> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/proxy-oidc/start',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Redirect
     * @throws ApiError
     */
    public getProxyOidcCallback({
        state,
        code,
        error,
    }: {
        state: string,
        code?: string,
        error?: string,
    }): CancelablePromise<Redirect> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/proxy-oidc/callback',
            query: {
                'state': state,
                'code': code,
                'error': error,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public postProxyOidcRenew(): CancelablePromise<{
        jwt: string;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/proxy-oidc/renew',
        });
    }

}
