/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SalesConfigPaymentAccountResult } from '../models/SalesConfigPaymentAccountResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SalesConfigPaymentAccountsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns SalesConfigPaymentAccountResult
     * @throws ApiError
     */
    public getSalesConfigPaymentAccountsIndex(): CancelablePromise<Array<SalesConfigPaymentAccountResult>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/sales/config/payments/accounts',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public postSalesConfigPaymentAccountsCreate({
        requestBody,
    }: {
        requestBody: {
            redirectUrl: string;
        },
    }): CancelablePromise<({
        ok: boolean;
    } | {
        url: string;
    })> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sales/config/payments/accounts',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public postSalesConfigPaymentAccountsCreateWithOauthCode({
        requestBody,
    }: {
        requestBody: {
            code: string;
        },
    }): CancelablePromise<{
        ok: boolean;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/sales/config/payments/accounts/oauth-code',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
