/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CrmPublicWebhookService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns any
     * @throws ApiError
     */
    public postCrmPublicWebhookPostBblJotform({
        requestBody,
    }: {
        requestBody: {
            rawRequest: string;
        },
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/public/crm/webhook/bbl-jotform',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
