/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IIdentity } from '../models/IIdentity';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AccountsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns IIdentity
     * @throws ApiError
     */
    public getAccountsLookup({
        ids,
    }: {
        ids?: string,
    }): CancelablePromise<Array<IIdentity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/accounts',
            query: {
                'ids': ids,
            },
        });
    }

}
