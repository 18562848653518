/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SalesGiftCardsPublicRenderService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns any
     * @throws ApiError
     */
    public getSalesGiftCardsPublicRenderRenderGiftCard({
        id,
        format,
        tenantId,
        deliveryId,
    }: {
        id: string,
        format: 'pdf' | 'html',
        tenantId: string,
        deliveryId: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/sales/gift-cards/{id}/render',
            path: {
                'id': id,
            },
            query: {
                'format': format,
                'tenantId': tenantId,
                'deliveryId': deliveryId,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public getSalesGiftCardsPublicRenderRenderGiftCardEmail({
        id,
        tenantId,
        deliveryId,
    }: {
        id: string,
        tenantId: string,
        deliveryId: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/sales/gift-cards/{id}/render-email',
            path: {
                'id': id,
            },
            query: {
                'tenantId': tenantId,
                'deliveryId': deliveryId,
            },
        });
    }

}
