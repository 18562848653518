/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IPublicFormDetailedResponse } from '../models/IPublicFormDetailedResponse';
import type { ISubmissionResponse } from '../models/ISubmissionResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FormsPublicFormsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns IPublicFormDetailedResponse
     * @throws ApiError
     */
    public getFormsPublicFormsGetForm({
        tenantId,
        id,
    }: {
        tenantId: string,
        id: string,
    }): CancelablePromise<IPublicFormDetailedResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/public/forms/{tenantId}/{id}',
            path: {
                'tenantId': tenantId,
                'id': id,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public postFormsPublicFormsSubmitForm({
        tenantId,
        formId,
        definitionId,
        requestBody,
    }: {
        tenantId: string,
        formId: string,
        definitionId: string,
        requestBody: {
            languageCode?: string;
            responses: Array<ISubmissionResponse>;
        },
    }): CancelablePromise<{
        ok: boolean;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/public/forms/{tenantId}/{formId}/{definitionId}/submissions',
            path: {
                'tenantId': tenantId,
                'formId': formId,
                'definitionId': definitionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public postFormsPublicFormsResolveZip({
        tenantId,
        id,
        requestBody,
    }: {
        tenantId: string,
        id: string,
        requestBody: {
            zip: string;
        },
    }): CancelablePromise<Array<{
        state: string;
        city: string;
    }>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/public/forms/{tenantId}/{id}/resolve-zip',
            path: {
                'tenantId': tenantId,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
