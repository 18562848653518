/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ICoreInstallNameResponse } from '../models/ICoreInstallNameResponse';
import type { ICoreInstallResponse } from '../models/ICoreInstallResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CoreInstallsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns ICoreInstallResponse
     * @throws ApiError
     */
    public getCoreInstallsList(): CancelablePromise<Array<ICoreInstallResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/core/installs',
        });
    }

    /**
     * @returns ICoreInstallNameResponse
     * @throws ApiError
     */
    public getCoreInstallsResolveNames({
        ids,
    }: {
        ids: string,
    }): CancelablePromise<Array<ICoreInstallNameResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/core/installs/resolve-names',
            query: {
                'ids': ids,
            },
        });
    }

}
